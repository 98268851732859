import { ArrowLeftOutlined } from "@ant-design/icons";
import { Space, Typography, Button, List } from "antd";
import EventDrawer from "components/EventDrawer/EventDrawer";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentBooking } from "redux/actions/booking.actions";
import { addonsTextReducer, convertMinsToHrsMins } from "utils/common";

import styles from "./ProfileDrawer.module.scss";

const { Title, Text } = Typography;
const OrderStatus = {
  Pending: 1,
  Cancel: 2,
  Completed: 3,
  "No Show": 4,
  Arrived: 5,
};
const AppointmentCard = ({ appointment, onClick }) => {
  const { orderId, lineItems, orderStatus, orderStatusString } = appointment;
  const getOrderStatus = (statusId, order) => {
    for (const key in OrderStatus) {
      if (statusId === OrderStatus[key]) {
        return key;
      }
    }
  };
  // TODO: DONT USE ORDERSTATUS_STRING
  let statusColor = "";
  if (orderStatusString === "Pending") {
    statusColor = "#27ca2d";
  } else if (orderStatusString === "Arrived") {
    statusColor = "#27ca2d";
  } else if (orderStatusString === "NoShow") {
    statusColor = " #fa8c16";
  } else if (orderStatusString === "Cancel") {
    statusColor = " #fa8c16";
  } else if (orderStatusString === "Completed") {
    statusColor = "#27ca2d";
  } else if (orderStatusString !== "Arrived") {
    statusColor = "#486baf ";
  }

  return (
    <div className="ppointmentCard">
      <Title
        level={5}
        style={{
          color: "#486BAF",
          cursor: "pointer",
        }}
        onClick={() => onClick(appointment)}
      >
        {lineItems?.[0]?.productName ?? "NA"}
        {lineItems?.[0]?.durationHours !== undefined &&
          lineItems?.[0]?.durationHours >= 1 &&
          ` ${convertMinsToHrsMins(
            Number(lineItems?.[0]?.durationHours) * 60
          )}`}
        {lineItems?.[0]?.addOns?.length > 0 &&
          ` with ${lineItems?.[0]?.addOns?.reduce(addonsTextReducer, "")} `}
      </Title>
      <Space direction="vertical">
        <Text>
          {moment(appointment.serviceDate).format("MMMM, DD YYYY - h:mm a")}
        </Text>
        <Text style={{ fontSize: 14 }}>
          Status:{" "}
          <b
            style={{
              color: statusColor,
            }}
          >
            {getOrderStatus(orderStatus) === "Cancel"
              ? "Cancelled"
              : getOrderStatus(orderStatus)}
          </b>
        </Text>
      </Space>
    </div>
  );
};

export default function AppointmentsTab({ status, onClose }) {
  // useEffect(() => {}, [status]);
  const dispatch = useDispatch();
  const [showAppointment, setShowAppointment] = useState(false);
  const { customerInfo } = useSelector((state) => state);
  const { totalBookings, upcomingBookings, noShowBookings } = customerInfo;
  let renderList = totalBookings;
  if (status === 1) renderList = upcomingBookings;
  else if (status === 3) renderList = noShowBookings;

  return (
    <div className={styles.appointmentTabContainer}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "10px 0px",
          borderBottom: "2px solid rgb(239, 241, 244)",
        }}
      >
        <Button
          shape="circle"
          icon={<ArrowLeftOutlined />}
          style={{
            border: "none",
            marginRight: 4,
          }}
          onClick={() => onClose()}
        />

        <Title level={4} style={{ marginBottom: 0 }}>
          {status === 0 && "Total Appointments"}
          {status === 1 && "Upcoming Appointments"}
          {status === 3 && "No Shows"}
        </Title>
      </div>
      <List
        style={{
          height: "calc(100vh - 104px - 54px)",
          overflowY: "scroll",
        }}
        className="customerAppointmentsList"
        itemLayout="vertical"
        size="large"
        dataSource={renderList}
        renderItem={(appointment, index) => (
          <List.Item key={index}>
            <AppointmentCard
              key={appointment.orderId ?? index}
              appointment={appointment}
              onClick={async (booking) => {
                await dispatch(setCurrentBooking(booking));
                setShowAppointment(true);
              }}
            />
          </List.Item>
        )}
      />
      <EventDrawer
        visible={showAppointment}
        onClose={() => setShowAppointment(false)}
        setShowEventDrawer={setShowAppointment}
      />
    </div>
  );
}
