import React, { useMemo } from "react";
import styles from "./PaymentMethodManager.module.scss";
import {
  Button as AntdButton,
  Divider,
  Modal,
  Spin,
  Tag,
  Typography,
} from "antd";
import { DeleteFilled, DeleteOutlined } from "@ant-design/icons";
import { Button } from "components/Buttons/Buttons";
import { ReactComponent as CheckMarkIcon } from "assets/icons/checkMarkIco.svg";
import AddCardModal from "components/AddCardModal/AddCardModal";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePaymentMethid,
  setPrimaryPaymentMethod,
} from "services/guests.services";
import { setLoading } from "redux/actions/app.actions";

const { Text, Title } = Typography;

export default function PaymentMethodManager({
  customerId,
  paymentMethods,
  fetchingPaymentMethods,
  refetchPaymentMethods,
}) {
  const [showAddCard, setShowAddCard] = React.useState(false);
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user?.currentUser);

  const hasDeleteCCPermission = useMemo(() => {
    return user?.permissions?.find(
      (permission) => permission.permissionName === "CC Edit"
    );
  }, [user]);

  if (fetchingPaymentMethods) {
    return <Spin />;
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.list}>
          {paymentMethods?.length === 0 && (
            <p className={styles.noDataText}>No payment methods</p>
          )}
          {paymentMethods?.map?.((paymentMethod) => {
            const { brand, last4Digits } = paymentMethod ?? {};

            return (
              <div key={paymentMethod?.paymentMethodId} className={styles.card}>
                <div className={styles.cardLeftSection}>
                  <span
                    style={{ width: 30, height: 26 }}
                    className={`cardChip issuer-${brand}`}
                  >
                    &nbsp;
                  </span>
                  <Text className={styles.cardBrandName}>{brand}</Text>
                  <Text style={{}}>ending in {last4Digits}</Text>
                </div>
                <div className={styles.cardRightSection}>
                  {/* <Divider type="vertical" /> */}
                  {paymentMethod.isPrimary ? (
                    <Tag icon={<CheckMarkIcon />} className="primaryTag">
                      Primary
                    </Tag>
                  ) : (
                    <AntdButton
                      type="text"
                      style={{ padding: 0 }}
                      onClick={async () => {
                        dispatch(setLoading(true));
                        const res = await dispatch(
                          setPrimaryPaymentMethod(
                            paymentMethod.paymentMethodId,
                            customerId
                          )
                        );
                        if (res.status === "success") {
                          refetchPaymentMethods();
                        }
                        dispatch(setLoading(false));
                      }}
                    >
                      Set as Primary
                    </AntdButton>
                  )}

                  <AntdButton
                    hidden={!hasDeleteCCPermission}
                    disabled={!hasDeleteCCPermission}
                    size="small"
                    danger
                    shape="circle"
                    icon={<DeleteFilled />}
                    onClick={() => {
                      Modal.confirm({
                        closable: true,
                        maskClosable: true,
                        title: "Confirmation",
                        content:
                          "Are you sure you want to delete the credit card on file?",
                        onOk: () => {
                          dispatch(
                            deletePaymentMethid(
                              paymentMethod.paymentMethodId,
                              customerId,
                              refetchPaymentMethods
                            )
                          );
                        },
                      });
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <div className={styles.footer}>
          <Button
            rounded
            type="primary"
            onClick={() => {
              setShowAddCard(true);
            }}
          >
            Add Payment Method
          </Button>

          {/* <AntdButton type="text" style={{ padding: 0 }}>
            View Audit History
          </AntdButton> */}
        </div>
      </div>
      <AddCardModal
        customerId={customerId}
        visible={showAddCard}
        forcePrimary={paymentMethods?.length === 0}
        handleSave={() => {
          refetchPaymentMethods();
          setShowAddCard(false);
        }}
        onCancel={() => {
          setShowAddCard(false);
        }}
      />
    </>
  );
}
