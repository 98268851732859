import React from "react";
import { Radio, Space, Typography } from "antd";
import "./PaymentMethodView.scss";

const { Text, Title } = Typography;

export default function PaymentMethod({
  paymentMethod,
  paymentMethodId,
  onClick,
}) {
  const { brand, last4Digits } = paymentMethod ?? {};

  return (
    <div
      key={paymentMethod?.paymentMethodId}
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: 12,
        cursor: "pointer",
      }}
      onClick={() => {
        onClick(paymentMethod.paymentMethodId);
      }}
    >
      <Space>
        <Radio checked={paymentMethodId === paymentMethod.paymentMethodId} />
      </Space>
      <div className="cardDetails ml-12">
        <span
          style={{ width: 30, height: 26 }}
          className={`cardChip issuer-${brand}`}
        >
          &nbsp;
        </span>
        <Text className="cardBrandName">{brand}</Text>
        <Text style={{}}>ending in {last4Digits}</Text>
      </div>
    </div>
  );
}
