import React, { useCallback, useEffect, useState } from "react";

import {
  DeleteOutlined,
  EditOutlined,
  ReloadOutlined,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import {
  Col,
  Row,
  Space,
  Button as AntdButton,
  Table,
  Modal,
  Form,
} from "antd";
import {
  FormCheckbox,
  FormInputArea,
} from "components/FormItems/FlatFormItems";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "redux/actions/app.actions";
import {
  createCustomerNotes,
  deleteNote,
  getCustomerNotes,
  updateCustomerNotes,
} from "services/guests.services";

export default function NotesGrid({ form, customerId, isEditable = true }) {
  const [notes, setNotes] = useState([]);
  const [showNoteForm, setShowNoteForm] = useState(false);
  const [starredChecked, setStarredChecked] = useState(false);
  const selectedNoteTemplate = {
    noteId: "",
    customerId: "",
    isStarred: false,
    createdBy: "",
    createdOn: "",
    noteDescription: "",
  };
  const [selectedNote, setSelectedNote] = useState(null);
  const loggedUser = useSelector((state) => state.user.currentUser);
  const dispatch = useDispatch();

  const reset = () => {
    setShowNoteForm(false);
    setNotes([]);
    form.resetFields();
  };

  const loadData = useCallback(() => {
    if (customerId) {
      dispatch(getCustomerNotes(customerId)).then((res) => {
        if (res?.status === "success") setNotes(res?.data);
      });
    }
  }, [customerId]);

  useEffect(() => {
    reset();
  }, [customerId]);

  useEffect(() => {
    loadData();
  }, [customerId]);

  const columns = [
    {
      title: "Notes",
      dataIndex: "noteDescription",
      key: "noteDescription",
      render: (text, record) => {
        const utc = moment.utc(record.createdOn);
        const cst = utc.tz("America/Chicago");

        return (
          <td style={{ display: "grid" }}>
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                gap: 4,
              }}
            >
              {record?.isStarred && (
                <StarFilled
                  style={{
                    color: "#F9C237",
                  }}
                />
              )}
              <span style={{ fontWeight: "inherit" }}>
                {record.noteDescription}
              </span>
            </div>

            <span style={{ fontSize: "12px", color: "gray" }}>
              {cst.format("MMMM, DD YYYY - h:mm a")} (CST)
            </span>
          </td>
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      width: 100,
      ellipisis: true,
      textWrap: "word-break",
      render: (text, record) => {
        return (
          <p
            style={{
              width: 100,
              overflow: "hidden",
              textOverflow: "ellipsis",
              // whiteSpace: 'nowrap',
            }}
          >
            {text}
          </p>
        );
      },
    },
    // {
    //   title: "Created On",
    //   dataIndex: "createdOn",
    //   key: "createdOn",
    //   width: 150,
    //   ellipsis: true,
    //   render: (text, record) => {
    //     return <>{moment(record.createdOn).format("YYYY-MM-DD HH:mm:ss")}</>;
    //   },
    // },

    {
      title: "",
      key: "action",
      align: "right",
      width: 80,
      render: (text, record) => (
        // <CheckCircleOutlined style={{ fontSize: 20, color: "#52c41a" }} />
        <Space size={"small"}>
          {loggedUser?.roleName === "Admin" ||
          loggedUser?.email === record?.createdBy ? (
            <EditOutlined
              onClick={() => {
                // alert("edit");
                form.setFieldsValue({
                  noteDescription: record.noteDescription,
                  isStarred: record.isStarred,
                });
                // setStarredChecked(true);
                setSelectedNote(record);
                setShowNoteForm(true);
                setTimeout(() => {
                  form.scrollToField("noteDescription", {
                    scrollMode: "if-needed",
                    block: "center",
                    behavior: "smooth",
                  });
                }, 200);
              }}
            />
          ) : null}

          {(loggedUser?.roleName === "Admin" ||
            loggedUser?.email === record?.createdBy) && (
            <DeleteOutlined
              onClick={() => {
                Modal.confirm({
                  title: "Confirm",
                  okText: "Ok",
                  cancelText: "Cancel",
                  content: "Are you sure you want to delete this note?",
                  onOk: async () => {
                    await dispatch(
                      deleteNote(record.customerId, record.noteId, loadData)
                    );
                    // profileClickHandler(user);
                  },
                });
              }}
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <Row>
      <Col xs={24}>
        <div
          size={"small"}
          direction="horizontal"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginBottom: 16,
            gap: 4,
          }}
        >
          <AntdButton
            disabled={!customerId}
            size="small"
            rounded={true}
            shape="circle"
            onClick={loadData}
            icon={<ReloadOutlined />}
          />
          <AntdButton
            disabled={!customerId}
            htmlType="button"
            rounded
            style={{
              display: "flex",
              alignItems: "center",
            }}
            type="text"
            onClick={() => {
              setSelectedNote(null);
              setShowNoteForm(true);
              setSelectedNote(null);
              form.resetFields();
              setTimeout(() => {
                form.scrollToField("noteDescription", {
                  scrollMode: "if-needed",
                  block: "center",
                  behavior: "smooth",
                });
              }, 200);
            }}
          >
            Add Note
          </AntdButton>
        </div>

        <Table
          tableLayout="auto"
          locale={{ emptyText: "No Notes" }}
          dataSource={notes?.filter((note) => {
            if (isEditable) return true;
            else if (isEditable === false && note.isStarred === true)
              return true;
            return false;
          })}
          columns={columns}
          // columns={columns.filter((column) => {
          //   if (!hiddenColumns.includes(column.title)) return true;
          //   // return false;
          // })}
          pagination={{
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: [15, 25, 50, 100, 250],
          }}
          scroll={{
            // y: "calc(100vh - 80px - 96px - 60px - 100px)",
            x: true,
          }}
        />

        {showNoteForm && (
          <Form
            preserve={false}
            // initialValues={{
            //   noteDescription: selectedNote.noteDescription,
            // }}
            form={form}
            layout="vertical"
            onFinishFailed={(errors) => {
              console.log("errors", errors);
            }}
            onFinish={async (values) => {
              dispatch(setLoading(true));
              if (selectedNote) {
                await dispatch(
                  updateCustomerNotes(
                    {
                      noteDescription: values.noteDescription,
                      isStarred: values.isStarred ? true : false,
                    },
                    customerId,
                    selectedNote.noteId,
                    loadData
                  )
                );
              } else {
                await dispatch(
                  createCustomerNotes(
                    [
                      {
                        noteDescription: values.noteDescription,
                        isStarred: values.isStarred ? true : false,
                      },
                    ],
                    customerId,
                    loadData
                  )
                );
              }
              setSelectedNote(null);
              dispatch(setLoading(false));
              // setProfileVisible(false);

              //   profileClickHandler(user);
              setShowNoteForm(false);
              //   setShowEventDrawer && setShowEventDrawer(false);
            }}
          >
            <FormInputArea
              label={selectedNote ? "Edit Note" : "Add Note"}
              name={"noteDescription"}
              formItemStyles={{ width: "100%" }}
              required={true}
              // initialValue={{
              //   noteDescription: selectedNote.noteDescription,
              // }}
            />

            <FormCheckbox
              name="isStarred"
              className="checkboxRegular"
              formItemStyles={{ marginBottom: 14 }}
              onChange={async (e) => {
                // if (e.target.checked) {
                // setStarredChecked(e.target.checked);
              }}
              // initialValue={{
              //   isStarred: starredChecked ? true : false,
              // }}
              // initialValue={starredChecked ? true : false}
            >
              Starred (
              <StarOutlined />)
            </FormCheckbox>
            <Space
              size={"small"}
              direction="horizontal"
              style={{ marginLeft: "72%" }}
            >
              <AntdButton
                htmlType="submit"
                rounded
                style={{
                  marginBottom: 16,
                  display: "flex",
                  alignItems: "center",
                }}
                type="text"
              >
                Save
              </AntdButton>
              <AntdButton
                htmlType="button"
                rounded
                style={{
                  marginBottom: 16,
                  display: "flex",
                  alignItems: "center",
                }}
                type="text"
                onClick={() => {
                  form.resetFields();
                  setShowNoteForm(false);
                }}
              >
                Cancel
              </AntdButton>
            </Space>
          </Form>
        )}
      </Col>
    </Row>
  );
}
