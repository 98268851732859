import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Space, Typography, Button as AntdButton, Radio, Grid } from "antd";

import Modal from "components/Modal/Modal";
import { Skeleton } from "antd";
import { Spin } from "antd";
import AddCardModal from "components/AddCardModal/AddCardModal";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import PaymentMethod from "components/PaymentMethodView/PaymentMethodView";
import { useDispatch } from "react-redux";
import { getPaymentMethodById } from "services/guests.services";

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;

function PaymentDetailRow({
  customerId,
  paymentMethodId,
  isMultipleGuestBooking,

  isParentBooking,
  willHostPay,
}) {
  const screens = useBreakpoint();

  const dispatch = useDispatch();

  const [paymentMethod, setPaymentMethod] = useState({
    data: null,
    isLoading: true,
  });

  useEffect(() => {
    if (paymentMethodId) {
      dispatch(getPaymentMethodById(paymentMethodId))
        .then((paymentMethod) => {
          setPaymentMethod({
            isLoading: false,
            data: paymentMethod?.data ?? null,
          });
        })
        .catch(() => {
          setPaymentMethod({
            isLoading: false,
            data: null,
          });
        });
    }
  }, [paymentMethodId]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: paymentMethod && !screens.md ? "column" : "row",
          justifyContent: "space-between",
          alignItems: paymentMethod && screens.md ? "center" : "center",
          width: "100%",
        }}
      >
        <Space style={{ width: "100%", justifyContent: "space-between" }}>
          <Title level={4} style={{ fontSize: 17, color: "#000", margin: 0 }}>
            Payment
          </Title>
          {paymentMethod?.isLoading ? (
            <Spin style={{ marginLeft: 28 }} />
          ) : (
            <>
              {paymentMethod && (
                <div
                  className="cardDetails"
                  key={paymentMethod?.data?.paymentMethodId}
                >
                  <span
                    style={{ width: 30, height: 26 }}
                    className={`cardChip issuer-${paymentMethod?.data?.brand}`}
                  >
                    &nbsp;
                  </span>
                  <Text className="cardBrandName">
                    {paymentMethod?.data?.brand}
                  </Text>
                  <Text style={{}}>
                    ending in {paymentMethod?.data?.last4Digits}
                  </Text>
                </div>
              )}
            </>
          )}
        </Space>
      </div>
      {isMultipleGuestBooking && !isParentBooking && (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          {willHostPay ? (
            <CheckCircleOutlined style={{ fontSize: 20, color: "#52c41a" }} />
          ) : (
            <CloseCircleOutlined style={{ fontSize: 20, color: "#ff4d4f" }} />
          )}

          <Text style={{ marginBottom: 0, fontWeight: 600 }}>
            &nbsp; {willHostPay ? "Will be paid by host" : "Not Paid by host"}
          </Text>
        </div>
      )}
    </>
  );
}

export default PaymentDetailRow;
