import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  CrownOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  MailOutlined,
} from "@ant-design/icons";
import {
  Drawer,
  Tabs,
  Collapse,
  Avatar,
  Row,
  Col,
  Form,
  Popover,
  Button as AntdButton,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import styles from "./ProfileDrawer.module.scss";
import moment from "moment-timezone";
import FilesTab from "./FilesTab";
import IntakeForms from "containers/IntakeForms/IntakeForms";
import ChartsTab from "./ChartsTab";
import {
  FormDatePicker,
  FormHiddenSubmitButton,
  FormInput,
  FormPhoneNumber,
} from "components/FormItems/FlatFormItems";
import { Button } from "components/Buttons/Buttons";
import {
  sendWelcomeEmail,
  updateGuest,
  getCustomerPaymentMethods,
  // getCustomerWalletCredits,
  generateProfilePDF,
  getCustomerWallets,
  sendEmail,
} from "services/guests.services";
import { useDispatch } from "react-redux";
import AddressesInformation from "components/AddressesInformation/AddressesInformation";
import AppointmentsTab from "./AppointmentsTab";
import CustomerBookingCounts from "components/CustomerBookingCounts/CustomerBookingCounts";
import NotesGrid from "components/NotesGrid/NotesGrid";
import ProgramsList from "components/ProgramsList/ProgramsList";
import GiftCardRow from "components/GiftCardRow/GiftCardRow";
import { getCustomerGiftCards } from "services/giftCards.service";
import { setAppointmentDrawer } from "redux/actions/drawer.actions";
import { setSelectedGuest } from "redux/actions/scheduler.actions";
import AddEmailAddresses from "./components/AddEmailAddresses";
import EmailModal from "components/EmailModal/EmailModal";
import PaymentMethodManager from "./components/PaymentMethodManager";

const TabPane = Tabs.TabPane;
export default function ProfileDrawer({
  data,
  user,
  profileVisible,
  setProfileVisible,
  setShowEventDrawer,
  onSave,
  refreshCustomer,
}) {
  const [form] = Form.useForm();
  const [notesForm] = Form.useForm();
  const dispatch = useDispatch();
  // const [credit, setCredits] = useState({
  //   data: 0,
  //   isLoading: true,
  // });
  const [paymentMethods, setPaymentMethods] = useState();
  const [customerWallets, setCustomerWallets] = useState({
    data: [],
    isLoading: true,
  });
  const [fetchingPaymentMethods, setFetchingPaymentMethods] = useState(false);
  const [showSendEmail, setshowSendEmail] = useState(false);

  const [giftCards, setGiftCards] = useState({
    data: [],
    isLoading: false,
  });
  const [showEmailSendModal, setShowEmailModal] = useState(false);
  // O for Total Appointments
  // 1 for Upcoming Appointments
  // 2 for No Show Appointments
  const [showAppointments, setShowAppointments] = useState();

  // undefined or string
  const [showGoogleMap, setshowGoogleMap] = useState();
  const submitBtnRef = useRef();

  const {
    email: emailProp,
    phoneNumber: phoneNumberProp,
    primaryAddress: primaryAddressProp,
    dob: dobProp,
  } = user ?? {};

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const getCustomerPaymentMethodsLists = async (customerId) => {
    setFetchingPaymentMethods(true);
    dispatch(
      getCustomerPaymentMethods(customerId, (paymentMethods) => {
        setPaymentMethods(paymentMethods);
      })
    ).finally(() => {
      setFetchingPaymentMethods(false);
    });
  };

  const populateCustomerGiftCards = (customerId) => {
    setGiftCards((data) => ({ ...data, isLoading: true }));
    dispatch(getCustomerGiftCards(customerId))
      .then((res) => {
        if (res?.status === "success") {
          setGiftCards((data) => ({ data: res?.data ?? [], isLoading: false }));
        }
      })
      .finally(() => {
        setGiftCards((data) => ({ ...data, isLoading: false }));
      });
  };

  // const populateWalletCredits = (customerId) => {
  //   if (customerId) {
  //     setCredits((state) => ({ ...state, isLoading: true }));
  //     dispatch(getCustomerWalletCredits(customerId))
  //       .then((res) => {
  //         if (res?.status === "success") {
  //           setCredits((state) => ({ ...state, data: res?.data }));
  //         }
  //       })
  //       .finally(() => {
  //         setCredits((state) => ({ ...state, isLoading: false }));
  //       });
  //   }
  // };

  const populateCustomerWallets = (customerId) => {
    if (customerId) {
      setCustomerWallets((state) => ({ ...state, isLoading: true }));
      dispatch(getCustomerWallets(customerId))
        .then((res) => {
          if (res?.status === "success") {
            setCustomerWallets((state) => ({ ...state, data: res?.data }));
          }
        })
        .finally(() => {
          setCustomerWallets((state) => ({ ...state, isLoading: false }));
        });
    }
  };

  useEffect(() => {
    if (profileVisible)
      form.setFieldsValue({
        phoneNumber: phoneNumberProp,
        email: emailProp,
        dob: dobProp ? moment(dobProp) : undefined,
      });
  }, [user?.customerId, profileVisible]);

  useEffect(() => {
    if (profileVisible === false) {
      setShowAppointments();
      // dispatch(clearCustomerNotes());
      form.resetFields();
      // setCredits({
      //   data: 0,
      //   isLoading: false,
      // });
      setGiftCards({
        data: [],
        isLoading: false,
      });
      setCustomerWallets({
        data: [],
        isLoading: false,
      });
    } else if (!!user?.customerId) {
      populateCustomerGiftCards(user?.customerId);
      // populateWalletCredits(user?.customerId);
      getCustomerPaymentMethodsLists(user?.customerId);
      populateCustomerWallets(user?.customerId);
    }
  }, [profileVisible, user]);

  const paymentMethodId =
    paymentMethods?.find?.((paymentMethod) => paymentMethod?.isPrimary === true)
      ?.paymentMethodId ?? paymentMethods?.[0]?.paymentMethodId;

  return (
    <Drawer
      visible={profileVisible}
      width={"min(100vw, 580px)"}
      closable={false}
      destroyOnClose
      headerStyle={{ border: "none" }}
      className={styles.profileDrawer}
      onClose={() => {
        setProfileVisible(false);
        // setShowNoteForm(false);
      }}
      title={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            minHeight: 68,
            color: "#000",
            borderBottom: "2px solid #EFF1F4",
          }}
        >
          <div style={{ display: "flex" }}>
            <Avatar size={"large"} style={{ marginRight: 12 }}>
              <p style={{ margin: 0 }}>
                {user?.firstName[0]}
                {user?.lastName[0]}
              </p>
            </Avatar>
            <div>
              <p
                style={{
                  margin: 0,
                  fontSize: 24,
                  fontFamily: "tradeGothic",
                  fontWeight: "bold",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                {user?.firstName} {user?.lastName}
                <Popover
                  content={
                    user?.isRegistrationComplete
                      ? "User is active"
                      : "User is not active"
                  }
                >
                  {user?.isRegistrationComplete ? (
                    <CheckCircleOutlined
                      style={{ fontSize: 22, color: "#52c41a", marginLeft: 8 }}
                    />
                  ) : (
                    <CloseCircleOutlined
                      style={{ fontSize: 22, color: "#f5222d", marginLeft: 8 }}
                    />
                  )}
                </Popover>
                {user?.hasMembership && (
                  <Popover content={"User has membership"}>
                    <CrownOutlined
                      style={{
                        fontSize: 28,
                        paddingLeft: 4,
                        color: "#ffa900",
                      }}
                    />
                  </Popover>
                )}
              </p>
              <p style={{ marginTop: 12 }}>
                Customer ID:{`${user?.customerId}`}
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignSelf: "flex-start",
              flexDirection: "column",
              gap: 8,
            }}
          >
            <div style={{ display: "flex", alignSelf: "flex-start" }}>
              <Button
                rounded
                style={{
                  height: 30,
                  marginRight: 12,
                  padding: "0px 12px",
                }}
                className="appBookAppointmentButton"
                onClick={() => {
                  dispatch(setSelectedGuest(user));

                  dispatch(
                    setAppointmentDrawer({
                      show: true,
                      data: null,
                    })
                  );
                  setProfileVisible(false);
                }}
              >
                Book an Appointment
              </Button>
              <CloseOutlined
                style={{ paddingTop: 6 }}
                onClick={() => {
                  setProfileVisible(false);
                  // setShowNoteForm(false);
                }}
              />
            </div>
            <div
              style={{ display: "flex", gap: 12, justifyContent: "flex-end" }}
            >
              <AntdButton
                size="small"
                shape="round"
                type="primary"
                icon={<DownloadOutlined />}
                onClick={async () => {
                  const report = await dispatch(
                    generateProfilePDF(user?.customerId, [], true)
                  );
                  if (report?.status === "success") {
                    window.open(report?.data, "_blank");
                  }
                }}
              />
              <AntdButton
                size="small"
                shape="round"
                type="primary"
                icon={<MailOutlined />}
                onClick={() => setshowSendEmail(true)}
              />
            </div>
          </div>
        </div>
      }
    >
      {showAppointments !== undefined && (
        <AppointmentsTab
          status={showAppointments}
          onClose={() => setShowAppointments()}
        />
      )}

      <div
        className={`${styles.profileTabsContainer} ${
          showAppointments !== undefined ? styles.hidden : ""
        }`}
      >
        <Tabs
          className={styles.mainTab}
          tabBarExtraContent={{
            right: user?.isRegistrationComplete ? (
              <></>
            ) : (
              <AntdButton
                type="text"
                style={{ margin: 0, padding: 0 }}
                onClick={() => dispatch(sendWelcomeEmail(user?.customerId))}
              >
                Send Welcome Email
              </AntdButton>
            ),
          }}
        >
          <TabPane tab="Profile" style={{ fontSize: 18, fontWeight: "bold" }}>
            {/* Upcoming and History Appointments Start */}
            <CustomerBookingCounts
              customerId={user?.customerId}
              onAppointmentTypeClick={(status) => setShowAppointments(status)}
            />
            {/* Upcoming and History Appointments End */}
            <Form
              form={form}
              layout="vertical"
              requiredMark={false}
              onFinishFailed={({ values, errorFields, outOfDate }) => {
                form.scrollToField(errorFields[0].name, {
                  scrollMode: "if-needed",
                  block: "center",
                  behavior: "smooth",
                });
              }}
              onFinish={(values) => {
                values.guestId = user?.customerId;
                values.address = primaryAddressProp;
                if (values.dob) {
                  values.dob = values.dob
                    ? moment(values.dob).toISOString()
                    : undefined;
                } else {
                  values.dob = user?.dob;
                }
                dispatch(updateGuest(values)).then((res) => {
                  if (res?.status === "success") onSave?.();
                });
              }}
            >
              <FormPhoneNumber
                formItemClassName="label-d-none "
                variant="underlined"
                name="phoneNumber"
                label=""
                placeholder="Mobile Number"
                required={true}
                formItemStyles={{ marginBottom: 12 }}
              />
              <FormInput
                formItemClassName="label-d-none "
                variant="underlined"
                name="email"
                label=""
                type="email"
                placeholder="Email Address"
                formItemStyles={{ marginBottom: 12 }}
                required={true}
                addonBefore={
                  <MailOutlined
                    style={{
                      fontSize: 17,
                    }}
                  />
                }
                addonAfter={
                  <AntdButton
                    type="text"
                    onClick={() => {
                      setShowEmailModal(true);
                    }}
                    style={{ paddingRight: 0 }}
                  >
                    Send Email
                  </AntdButton>
                }
              />
              <Collapse
                defaultActiveKey={"addressesInfo"}
                expandIconPosition={"end"}
                style={{
                  background: "#fff",
                  border: "none",
                  boxShadow: "none",
                }}
              >
                <Collapse.Panel
                  key="addressesInfo"
                  style={{ background: "#fff", boxShadow: "none" }}
                  header={
                    <p
                      style={{
                        margin: 0,
                        fontSize: 20,
                        fontFamily: "tradeGothic",
                        color: "#000000",
                        fontWeight: "bold",
                      }}
                    >
                      Addresses Information
                    </p>
                  }
                >
                  <AddressesInformation customerId={user?.customerId} />
                </Collapse.Panel>
              </Collapse>

              <FormHiddenSubmitButton submitRef={submitBtnRef} />

              <Collapse
                defaultActiveKey={"otherInfo"}
                expandIconPosition={"end"}
                style={{
                  background: "#fff",
                  border: "none",
                  boxShadow: "none",
                }}
              >
                <Collapse.Panel
                  key="otherInfo"
                  style={{ background: "#fff", boxShadow: "none" }}
                  header={
                    <div
                      style={{ display: "flex", gap: 8, alignItems: "center" }}
                    >
                      <p
                        style={{
                          margin: 0,
                          fontSize: 20,
                          fontFamily: "tradeGothic",
                          color: "#000000",
                          fontWeight: "bold",
                        }}
                      >
                        Other Information
                      </p>
                      {user && !user.dob && (
                        <ExclamationCircleOutlined
                          style={{ color: "#f5222d", fontSize: 17 }}
                        />
                      )}
                    </div>
                  }
                >
                  <div>
                    <FormDatePicker
                      form={form}
                      name="dob"
                      label="Date of Birth"
                      variant="underlined"
                      formItemStyles={{ marginBottom: 0 }}
                      rules={[
                        {
                          required: true,
                          message: "Required",
                        },
                      ]}
                      disabledDate={(current) =>
                        current.isSameOrAfter(
                          moment().set({
                            hour: 0,
                            minute: 0,
                            second: 0,
                            millisecond: 0,
                          })
                        )
                      }
                    />
                  </div>
                </Collapse.Panel>
              </Collapse>
            </Form>
            <Collapse
              expandIconPosition={"end"}
              style={{
                background: "#fff",
                border: "none",
                boxShadow: "none",
              }}
            >
              <Collapse.Panel
                key="paymentInfo"
                style={{ background: "#fff", boxShadow: "none" }}
                header={
                  <p
                    style={{
                      margin: 0,
                      fontSize: 20,
                      fontFamily: "tradeGothic",
                      fontWeight: "bold",
                      color: "#000000",
                    }}
                  >
                    Payment Information
                  </p>
                }
              >
                <Row>
                  <Col xs={24}>
                    <PaymentMethodManager
                      customerId={user?.customerId}
                      paymentMethods={paymentMethods}
                      fetchingPaymentMethods={fetchingPaymentMethods}
                      refetchPaymentMethods={() => {
                        getCustomerPaymentMethodsLists(user?.customerId);
                      }}
                    />
                  </Col>
                </Row>
              </Collapse.Panel>
            </Collapse>

            <Collapse
              expandIconPosition={"end"}
              style={{
                background: "#fff",
                border: "none",
                boxShadow: "none",
              }}
            >
              <Collapse.Panel
                key="intakeForms"
                style={{ background: "#fff", boxShadow: "none" }}
                header={
                  <p
                    style={{
                      margin: 0,
                      fontSize: 20,
                      fontFamily: "tradeGothic",
                      fontWeight: "bold",
                      color: "#000000",
                    }}
                  >
                    Intake Forms
                  </p>
                }
              >
                <Row>
                  <Col xs={24}>
                    <IntakeForms
                      user={user}
                      variant="full"
                      refreshCustomer={refreshCustomer}
                    />
                  </Col>
                </Row>
              </Collapse.Panel>
              <Collapse.Panel
                key="notes"
                style={{ background: "#fff", boxShadow: "none" }}
                header={
                  <p
                    style={{
                      margin: 0,
                      fontSize: 20,
                      fontFamily: "tradeGothic",
                      fontWeight: "bold",
                      color: "#000000",
                    }}
                  >
                    Notes
                  </p>
                }
              >
                <NotesGrid customerId={user?.customerId} form={notesForm} />
              </Collapse.Panel>
              <Collapse.Panel
                key="programsAndMemberships"
                style={{ background: "#fff", boxShadow: "none" }}
                header={
                  <p
                    style={{
                      margin: 0,
                      fontSize: 20,
                      fontFamily: "tradeGothic",
                      fontWeight: "bold",
                      color: "#000000",
                    }}
                  >
                    Programs and Memberships
                  </p>
                }
              >
                <ProgramsList
                  customerId={user?.customerId}
                  paymentMethodId={paymentMethodId}
                />
              </Collapse.Panel>
              <Collapse.Panel
                key="giftcards"
                style={{ background: "#fff", boxShadow: "none" }}
                header={
                  <p
                    style={{
                      margin: 0,
                      fontSize: 20,
                      fontFamily: "tradeGothic",
                      fontWeight: "bold",
                      color: "#000000",
                    }}
                  >
                    Gift Cards
                  </p>
                }
              >
                <GiftCardRow
                  view="concise"
                  // credit={credit?.data ?? 0}
                  // isCreditLoading={credit?.isLoading}
                  paymentMethod={
                    paymentMethods?.find?.(
                      (paymentMethod) => paymentMethod?.isPrimary === true
                    ) ??
                    paymentMethods?.[0] ??
                    null
                  }
                  customer={user}
                  customerId={user?.customerId}
                  loading={giftCards.isLoading}
                  giftCards={giftCards.data ?? []}
                  refetchGiftCards={() => {}}
                  refreshGiftCards={() =>
                    populateCustomerGiftCards(user?.customerId)
                  }
                  refreshCredits={() => {
                    // populateWalletCredits(user?.customerId);
                    populateCustomerWallets(user?.customerId);
                  }}
                  isCustomerWalletsLoading={customerWallets?.isLoading}
                  customerWallets={customerWallets?.data}
                />
              </Collapse.Panel>
            </Collapse>
            <Button
              style={{
                height: 50,
                width: 120,
                borderRadius: 30,
                marginTop: 30,
              }}
              onClick={() => {
                submitBtnRef?.current?.click();
              }}
            >
              Save
            </Button>
          </TabPane>
          <TabPane
            tab="Charts"
            key={2}
            style={{ fontSize: 18, fontWeight: "bold" }}
          >
            <ChartsTab user={user} />
          </TabPane>
          <TabPane
            tab="Files"
            key={3}
            style={{ fontSize: 18, fontWeight: "bold", paddingTop: 16 }}
          >
            <FilesTab user={user} />
          </TabPane>
          {/* <TabPane
            tab="Programs"
            key={4}
            style={{ fontSize: 18, fontWeight: "bold", paddingTop: 16 }}
          >
            <ProgramsTab />
          </TabPane> */}
        </Tabs>
      </div>
      <AddEmailAddresses
        guestId={user?.customerId}
        visible={showSendEmail}
        onClose={() => setshowSendEmail(false)}
      />
      <EmailModal
        visible={showEmailSendModal}
        onClose={() => setShowEmailModal(false)}
        user={user}
        saveText="Send"
      />
    </Drawer>
  );
}
