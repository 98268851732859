import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Space, Typography, Button as AntdButton, Radio, Grid } from "antd";

import "./MiniPaymentRow.scss";
import Modal from "components/Modal/Modal";
import { Skeleton } from "antd";
import { Spin } from "antd";
import AddCardModal from "components/AddCardModal/AddCardModal";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import PaymentMethod from "components/PaymentMethodView/PaymentMethodView";

const { Title, Text } = Typography;
const { useBreakpoint } = Grid;
function MiniPaymentRow({
  paymentMethods,
  paymentMethod,
  refetchPaymentMethods,
  onChange,
  onSave,
  loading,
  customerId,
  disabled,
  isMultipleGuestBooking,
  isParentBooking,
  willHostPay,
}) {
  //   const paymentMethod = paymentMethods?.find(
  //     (paymentMethod) => paymentMethod.paymentMethodId === id
  //   );
  const screens = useBreakpoint();
  const [showChangeModal, setShowChangeModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [tempPaymentMethodId, setTempPaymentMethodId] = useState(
    paymentMethod?.paymentMethodId
  );

  const { paymentMethodId, brand, last4Digits } = paymentMethod ?? {};

  useEffect(() => {
    if (paymentMethodId) {
      setTempPaymentMethodId(paymentMethodId);
    }
  }, [paymentMethodId]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: paymentMethod && !screens.md ? "column" : "row",
          justifyContent: "space-between",
          alignItems: paymentMethod && screens.md ? "center" : "center",
          width: "100%",
        }}
      >
        <Space style={{ width: "100%", justifyContent: "space-between" }}>
          <Title level={4} style={{ fontSize: 17, color: "#000", margin: 0 }}>
            Payment
          </Title>
          {loading ? (
            <Spin style={{ marginLeft: 28 }} />
          ) : (
            <>
              {paymentMethod && (
                <div
                  className="cardDetails"
                  key={paymentMethod?.paymentMethodId}
                >
                  <span
                    style={{ width: 30, height: 26 }}
                    className={`cardChip issuer-${brand}`}
                  >
                    &nbsp;
                  </span>
                  <Text className="cardBrandName">{brand}</Text>
                  <Text style={{}}>ending in {last4Digits}</Text>
                </div>
              )}
            </>
          )}
        </Space>
        {customerId !== undefined && (
          <Space size={2}>
            {paymentMethods?.length > 0 && (
              <AntdButton
                type="text"
                disabled={disabled}
                onClick={() => {
                  setShowChangeModal(true);
                }}
              >
                Change
              </AntdButton>
            )}
            <AntdButton
              type="text"
              disabled={disabled}
              onClick={() => {
                setShowAddModal(true);
              }}
            >
              Add
            </AntdButton>
          </Space>
        )}
      </div>
      {isMultipleGuestBooking && !isParentBooking && (
        <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
          {willHostPay ? (
            <CheckCircleOutlined style={{ fontSize: 20, color: "#52c41a" }} />
          ) : (
            <CloseCircleOutlined style={{ fontSize: 20, color: "#ff4d4f" }} />
          )}

          <Text style={{ marginBottom: 0, fontWeight: 600 }}>
            &nbsp; {willHostPay ? "Will be paid by host" : "Not Paid by host"}
          </Text>
        </div>
      )}

      <Modal
        // className="boostPopup"
        title="Select Payment Method"
        headerBorder={true}
        visible={showChangeModal}
        onCancel={() => {
          setTempPaymentMethodId(paymentMethodId);
          setShowChangeModal(false);
        }}
        onOk={() => {
          onChange(tempPaymentMethodId);
          setShowChangeModal(false);
        }}
      >
        <Text style={{ marginBottom: 14, display: "inline-block" }}>
          Select one of available payment methods
        </Text>
        <div>
          {paymentMethods?.map((paymentMethod) => {
            return (
              <PaymentMethod
                paymentMethod={paymentMethod}
                onClick={() => {
                  setTempPaymentMethodId(paymentMethod.paymentMethodId);
                }}
                paymentMethodId={tempPaymentMethodId}
              />
            );
          })}
        </div>
      </Modal>
      <AddCardModal
        customerId={customerId}
        visible={showAddModal}
        forcePrimary={paymentMethods?.length === 0}
        handleSave={(paymentMethod) => {
          refetchPaymentMethods(paymentMethod);
          onSave?.(paymentMethod);
          setShowAddModal(false);
        }}
        onCancel={() => {
          setShowAddModal(false);
        }}
      />
    </>
  );
}

MiniPaymentRow.propTypes = {};

export default MiniPaymentRow;
