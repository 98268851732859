import { useForm } from "antd/lib/form/Form";
import Modal from "components/Modal/Modal";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button as AntdButton, Col, Form, Row, Tag, Typography } from "antd";
import { Button } from "components/Buttons/Buttons";
import {
  FormCheckbox,
  FormHiddenSubmitButton,
  FormInputArea,
  FormInputNumber,
  FormSelect,
} from "components/FormItems/FlatFormItems";
import { getConfigByKey, updateConfigByKey } from "services/misc.service";
import { update } from "lodash";
import { openNotificationWithIcon } from "utils/Notification";
import { GIFTCARD_CONFIG_KEY } from "utils/constants";
import { getGiftCardDesign } from "services/giftCards.service";

const { Title } = Typography;

export default function GiftCardEditModal({ visible, onCancel, onSave }) {
  const dispatch = useDispatch();
  const [form] = useForm();
  const submitBtnRef = useRef();

  const [data, setData] = useState();

  const customValueEnabled = Form.useWatch("customValueEnabled", form);

  useEffect(() => {
    if (visible) {
      dispatch(getGiftCardDesign(true)).then((res) => {
        if (res?.status === "success") {
          form.setFieldsValue({
            ...res?.data,
          });
        }
      });
    } else {
      setData();
    }
  }, [visible]);

  const handleSubmit = (values) => {
    const json = encodeURI(JSON.stringify(values));
    dispatch(updateConfigByKey(GIFTCARD_CONFIG_KEY, json)).then((res) => {
      if (res?.status === "success") {
        onSave();
        openNotificationWithIcon(
          "success",
          "Update Successful",
          "Gift Card onfiguration has been updated successfully"
        );
      }
    });
    // console.log("decodeURI json", JSON.parse(decodeURI(json)));
  };

  return (
    <Modal
      className="ant-modal-title-tradeGothic"
      //   closable={closable}
      destroyOnClose={true}
      //   headerBorder={false}
      width="720px"
      title="Update Gift Card Configuration"
      onCancel={() => onCancel()}
      visible={visible}
      onOk={() => console.log("ok")}
      okText="Add"
      centered={true}
      footer={
        <div className="d-flex justify-between align-center">
          <AntdButton type="text" style={{ padding: 0 }}>
            Cancel
          </AntdButton>
          <Button
            style={{ height: 50 }}
            rounded={true}
            onClick={() => {
              submitBtnRef?.current?.click();
            }}
            //   disabled={!Boolean(setupIntent)}
          >
            Save
          </Button>
        </div>
      }
      //   cancelText="No"
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinishFailed={({ values, errorFields, outOfDate }) => {
          console.log(errorFields);
          form.scrollToField(errorFields[0].name, {
            scrollMode: "if-needed",
            block: "center",
            behavior: "smooth",
          });
        }}
        onFinish={handleSubmit}
      >
        <Row gutter={[24, 24]}>
          <Col xs={24} md={12}>
            <FormSelect
              variant="underlined"
              name="sellingPrices"
              label="Configure Gift Amounts"
              placeholder="Select/Add Guest Amounts"
              mode="tags"
              formItemStyles={{ marginBottom: 12 }}
              tagRender={({ label, value, closable, onClose }) => {
                const onPreventMouseDown = (event) => {
                  event.preventDefault();
                  event.stopPropagation();
                };
                return (
                  <Tag
                    onMouseDown={onPreventMouseDown}
                    closable={closable}
                    onClose={onClose}
                    style={{
                      marginRight: 3,
                    }}
                  >
                    ${value}
                  </Tag>
                );
              }}
              options={["25", "50", "75", "100"]}
              // isOptionDisabled={(it) => !it.isActive}
              // labelPrefix="$"
              required
            />
            <FormInputNumber
              variant="underlined"
              name="giftCardExpiryIntervalInDays"
              label="Expiry Interval (days)"
              // placeholder="Minimum Gift Card Value"
              // required={true}
              // min={0}
              formItemStyles={{
                margin: 0,
              }}
            />
          </Col>
          <Col
            xs={24}
            md={12}
            style={{
              paddingTop: 4,
            }}
          >
            <FormCheckbox
              name="customValueEnabled"
              className="checkboxRegular"
              formItemStyles={{ marginBottom: 14 }}
            >
              Enable Custom Gift Card Value
            </FormCheckbox>

            <FormInputNumber
              disabled={!customValueEnabled}
              variant="underlined"
              name="minimumValue"
              label="Enter Minimum Gift Card Value"
              placeholder="Minimum Gift Card Value"
              required={true}
              min={0}
              formItemStyles={{
                margin: 0,
              }}
            />
          </Col>
          <Col xs={24}>
            <Title
              variant={5}
              style={{
                margin: 0,
                fontSize: 16,
                color: "#161F3A",
                marginBottom: 12,
              }}
            >
              Terms & Conditions
            </Title>
            <FormInputArea
              name={"notes"}
              variant="underlined"
              formItemStyles={{ width: "100%", margin: 0 }}
            />
          </Col>
        </Row>
        <FormHiddenSubmitButton submitRef={submitBtnRef} />
      </Form>
    </Modal>
  );
}
